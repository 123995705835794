import Vue from "vue";
import VueRouter from "vue-router";
import AuthGuard from './auth-guard'

Vue.use(VueRouter)

export default new VueRouter({
    mode: "history",
    routes: [
        
        // == Login ==
        { 
            path: "/",
            name: "Login",
            component: () => import('@/views/Login/Login'),
            meta: { showHeader: false, showFooter: true },
        },
        


        // == Dashboard ==
        { 
            path: "/dashboard",
            name: "Dashboard",
            component: () => import('@/views/Dashboard/Dashboard'),
            meta: { showHeader: true, showFooter: false },
        },
        


        // == Report ==
        { 
            path: "/report",
            name: "Report",
            component: () => import('@/views/Report/Report'),
            meta: { showHeader: true, showFooter: false },
        },

    ]
});
