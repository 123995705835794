<template>
    <div>

      <!-- === HEADER === -->
      <v-app-bar
      fixed
      app
      elevate-on-scroll
      class="background-header"
      dark
      >

        <!-- === LEFT SECTION === -->
        <v-img
        class="mr-2"
        src="@/assets/img/logo/logo_kementerian.png"
        max-width="60"
        ></v-img>
        <v-img
        src="@/assets/img/logo/logo_mpdc.png"
        max-width="100"
        ></v-img>
        

        <div class="title-text pl-3" style="line-height: 0.5 !important">
          <v-toolbar-title class="primary--text text-bold">MARINE WATER QUALITY MONITORING SYSTEM</v-toolbar-title>
          <p class="text-subtitle-1 ma-0 pa-0 black--text">
            Department of Fisheries Malaysia
          </p>
        </div>


        <v-spacer></v-spacer>


        <!-- === RIGHT SECTION === -->
        <v-menu
        origin="center center"
        transition="scale-transition"
        offset-y
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                :color="$route.name == 'Dashboard'? 'thirdy': 'white'"
                icon
                rounded
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                @click="goToPage('/dashboard')"
                >
                  <v-icon role="img">mdi-view-dashboard-outline</v-icon>
                </v-btn>
              </template>
              <span>Dashboard</span>
            </v-tooltip>
          </template>
        </v-menu>

        <v-menu
        origin="center center"
        transition="scale-transition"
        offset-y
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                :color="$route.name == 'Report'? 'thirdy': 'white'"
                icon
                rounded
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                @click="goToPage('/report')"
                >
                  <v-icon role="img">mdi-folder-file-outline</v-icon>
                </v-btn>
              </template>
              <span>Report</span>
            </v-tooltip>
          </template>
        </v-menu>

        <!-- <v-menu
        origin="center"
        transition="scale-transition"
        offset-y
        class="mt-16"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                :color="$route.name == 'Report'? 'thirdy': 'white'"
                icon
                rounded
                v-bind="attrs"
                v-on="{ ...tooltip, ...on }"
                >
                  <v-icon color="white" role="img">mdi-folder-file-outline</v-icon>
                </v-btn>
              </template>
              <span>Report</span>
            </v-tooltip>
          </template>
          <v-list class="list-inChild" flat>
            <v-list-item-group
              color="primary"
              class="bbb"
            >
            <v-list-item
              v-for="(item, index) in listReport"
              :key="index"
              class="ccc"
              :to="item.url"
            >
              <v-list-item-title v-text="item.title" ></v-list-item-title>
            </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu> -->

        <v-menu
        origin="center center"
        transition="scale-transition"
        offset-y
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                color="white"
                icon
                rounded
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                @click="logoutSystem"
                >
                  <v-icon role="img">mdi-logout-variant</v-icon>
                </v-btn>
              </template>
              <span>Logout</span>
            </v-tooltip>
          </template>
        </v-menu>


      </v-app-bar>

    </div>
</template>

<script>
export default {
    data () {
      return {

        // listReport: [
        //     { title:'Daily Report' , url: "/report/dailyReport"},
        //     { title:'Monthly Report' , url: "/report/monthlyReport"},
        //     { title:'Exceedance Report' , url: "/report/exceedanceReport"},
        // ],

      }
    },

    mounted(){
      
    },

    methods: {

      loading() {
        return this.$store.getters.loading
      },

      logoutSystem() {
        this.$store.dispatch('signOut');
      },
      
    },

    computed: {
      user() {
          return this.$store.getters.user
      },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/main';

.background-header{
  // background-image: linear-gradient(to left,#015781, #ffffff);
  background: linear-gradient(25deg, #ffffff 30%, #015781 75%) 0 0/100% no-repeat;
  border-bottom: 5px solid #30aabc !important;
}

</style>

