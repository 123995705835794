<template>
  <v-app>
    <!-- app header -->
    <app-header v-if="$route.meta.showHeader"></app-header>

    <!-- content -->
    <v-main>
      <router-view :key="$route.path"></router-view>
    </v-main>

    <!-- app footer -->
    <app-footer v-if="$route.meta.showFooter"></app-footer>


    <v-dialog
    v-model="miniDialog"
    persistent
    max-width="350"
    >
      <v-card>
        <v-card-title :class="'text-h6 '+detailsMiniDialog.color+'--text'">
          <v-icon :class="'mr-2 '+detailsMiniDialog.color+'--text'">
            {{detailsMiniDialog.icon}}
          </v-icon>
          {{detailsMiniDialog.title}}
        </v-card-title>
        <v-card-text>
          {{detailsMiniDialog.context}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="primary text-bold"
          text
          @click="miniDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-app>
</template>

<script>
import { bus } from '@/main';

import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
export default {
  name: "App",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
  },

  data: () => ({

    miniDialog: false,
    detailsMiniDialog: {
      icon: '',
      title: '',
      context: '',
      status: '',
      notes: '',
      info: null,
      color: 'primary',
    }

  }),

  created (){
        bus.$on('triggerMiniPopup', (data) => {

          // console.log(data);

          if (data.focus == "inform") {

            let keysSender = Object.keys(data.info)
            let keysReceiver = Object.keys(this.detailsMiniDialog)

            for(let i in keysSender){
              for(let x in keysReceiver){
                if(keysSender[i] == keysReceiver[x]){
                  this.detailsMiniDialog[keysReceiver[x]] = data.info[keysSender[i]];
                }
              }
            }

            this.miniDialog = true;
            
          }

        })
  },

  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "~scss/main";
</style>
