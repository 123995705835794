export default {
    data() {
        return {

            globalUrl: "https://apis.serasi.tech/mpdc/"

        }
    },    
    mounted(){
        
    },
    methods: {

        goToPage(url){
            this.$router.push({ path: url }).catch(()=>{});
        },

        displayUiDate(datetime){

            if(datetime != null && datetime != "" && datetime.trim().length != 0 && datetime != "N/A" ){

                if(datetime.includes("T")){
                    datetime = datetime.replace("T"," ")
                }

                let [date, time] = datetime.split(' ');

                // Convert Date Format
                var dd = date.split("-")[2];
                var mm = date.split("-")[1];
                var yyyy = date.split("-")[0];
                let stringMM = [
                    {noMM: "01", strMM:"Jan"},
                    {noMM: "02", strMM:"Feb"},
                    {noMM: "03", strMM:"Mac"},
                    {noMM: "04", strMM:"Apr"},
                    {noMM: "05", strMM:"May"},
                    {noMM: "06", strMM:"Jun"},
                    {noMM: "07", strMM:"Jul"},
                    {noMM: "08", strMM:"Aug"},
                    {noMM: "09", strMM:"Sep"},
                    {noMM: "10", strMM:"Oct"},
                    {noMM: "11", strMM:"Nov"},
                    {noMM: "12", strMM:"Dec"},
                ];
                for(let i in stringMM){
                    if (stringMM[i].noMM == mm) {
                        mm = stringMM[i].strMM;
                    }
                }
                date = dd + " " + mm + " " + yyyy;

                // Convert Time to Format 12 hours
                var H = +time.substr(0, 2);
                // if(H.toString().length == 1){
                //     H += 0+H;
                // }
                // console.log(H);

                var h = H % 12 || 12;
                var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                // time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
                time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
                
                datetime = date + ", " + time;

                return datetime;

            }
            else{
                return null;
            }

        },

        displayDbDate(datetime){

            if(datetime != null && datetime != "" && datetime != "-"){
                
                let date = datetime.split(", ")[0];
                let time = datetime.split(", ")[1];

                // Change Date
                var yyyy = date.split(" ")[2];
                var mm = date.split(" ")[1];
                var dd = date.split(" ")[0];
                let stringMM = [
                    {noMM: "01", strMM:"Jan"},
                    {noMM: "02", strMM:"Feb"},
                    {noMM: "03", strMM:"Mac"},
                    {noMM: "04", strMM:"Apr"},
                    {noMM: "05", strMM:"May"},
                    {noMM: "06", strMM:"Jun"},
                    {noMM: "07", strMM:"Jul"},
                    {noMM: "08", strMM:"Aug"},
                    {noMM: "09", strMM:"Sep"},
                    {noMM: "10", strMM:"Oct"},
                    {noMM: "11", strMM:"Nov"},
                    {noMM: "12", strMM:"Dec"},
                ]
                for(let i in stringMM){
                    if (stringMM[i].strMM == mm) {
                        mm = stringMM[i].noMM;
                    }
                }
                let new_date = yyyy + "-" + mm + "-" + dd;

                // Change Time
                const [timeNew, modifier] = time.split(' ');
                let [hours, minutes, seconds] = timeNew.split(':');
                if (hours === '12'){   
                    hours = '00';
                }
                if(hours.length == 1){
                    hours = 0+hours;
                }
                
                if (modifier === 'PM'){
                    hours = parseInt(hours, 10) + 12;
                }

                let new_time = hours + ":" + minutes + ":" + seconds

                let new_datetime = new_date + " " + new_time;

                // console.log(new_datetime);

                return new_datetime;

            }
            else{
                return null;
            }

        }
        
    },
    computed: {

        // levelAdmin() {
        //     return this.$store.getters.userRole === 'admin' ? true : false
        // },

        // levelUser() {
        //     return this.$store.getters.userRole === 'user' ? true : false
        // },

    },
}
