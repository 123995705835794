import "ol/ol.css"
import Map from "ol/Map";
import View from "ol/View";
import {OSM, ImageWMS, Vector as VectorSource} from 'ol/source';
import {
    Image as ImageLayer,
    Tile as TileLayer, 
    Vector as VectorLayer,
    Group as GroupLayer,
} from 'ol/layer';

export default {

  data: () => ({

    // Tile Layer
    baseMapLayer: null,
    googleStreetMap: null,
    googleSateliteMap: null,
    streetMap: null,
    topographicMap: null,
    
  }),

  mounted() {
    this.loadTileLayer();
  },

  methods: {

    loadTileLayer(){

      // Layer Google Street Map
      this.googleStreetMap = new TileLayer({
          title: "Google Street",
          source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
          // url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
          }),
          visible: true,
          type: "base",
      });

      // Layer Google Street Map (Terrain)
      this.googleStreetMapTerrain = new TileLayer({
          title: "Google Terrain",
          source: new OSM({
          url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
      });
      // Layer Google Satelite Map
      this.googleSateliteMap = new TileLayer({
          title: "Google Satelite Without Label",
          source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
      });
      // Layer Google Satelite Map (With Label)
      this.googleSateliteMapLabel = new TileLayer({
          title: "Google Satelite With Label",
          source: new OSM({
          // url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          url: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga",
          crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
      });
      // Layer Street Map
      this.streetMap = new TileLayer({
          title: "OSM",
          source: new OSM({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
      });
      // Layer Topographic Map
      this.topographicMap = new TileLayer({
          title: "Topographic Map",
          source: new OSM({
          url: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.png",
          crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
      });


      // Grouping All Tile Layers
      let groupBase = [
          this.googleStreetMap,
          this.googleStreetMapTerrain,
          this.googleSateliteMap,
          this.googleSateliteMapLabel,
          this.streetMap,
          this.topographicMap
      ]
      this.baseMapLayer = new GroupLayer({
          layers: groupBase
      });

      

    },
    
  },

};


