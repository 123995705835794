import axios from 'axios'
import router from '@/router'
import { bus } from '@/main';
import appMixins from '@/mixins/globalMixins'

export default {
    state: {
        user: '',
        auth: {
            token: 'null',
        },
        loading: false,
        error: null,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        clearError(state) {
            state.error = null
        },
    },
    actions: {

        setUser({ commit }, payload) {
            commit('setUser', payload)
        },


        signUserIn({ commit, getters }, payload) {

            // console.log(payload);
            commit('setLoading', true)

            // console.log(appMixins.data().globalUrl + 'users/login');

            axios.post(appMixins.data().globalUrl + 'users/login', payload)
            .then((response) => {  
                
                console.log(response.data);
                
                commit('setLoading', false)
                commit('setUser', response.data)
                localStorage.setItem('userMPDC', JSON.stringify(response.data))
                router.push('/dashboard')
                
            })
            .catch((error) => {
                console.log(error.response.data);
                commit('setLoading', false)
                commit('setError', error.response.data)
                bus.$emit('triggerLogin', {focus: 'errorMessageLogin', info: error.response.data.message});
                // alert('wrong username/password')
                if (error.response.status === 401) {
                    this.signOut();
                }
            })
        },


        signOut({ commit }) {
            localStorage.clear();
            commit('setUser', null)
            commit('setError', null)
            router.push('/').catch(()=>{});
        },


        checkUserLocalStorage({ commit, getters }) {
            const user = JSON.parse(localStorage.getItem('userMPDC'))
            if (user) {
                commit('setUser', user)
            }
        },


        clearError({ commit }) {
            commit('clearError')
        },


        refreshToken({ state }) {
            //alert('token '+state.user.token)

            // console.log(state.user.token);
            console.log(state);

            return new Promise((resolve, reject) => {

                if(!state.user || !state.user.token){ 
                    console.log('no token found'); 
                    return false; 
                }
                else {
                    axios.get(appMixins.data().globalUrl + 'users/renewjwt?token='+state.user.token, null, { 
                        headers: {
                            Authorization: 'Bearer ' + state.user.token,
                        },
                    })
                    .then((response) => {
                        // update token on user state
                        // update token on localstorage

                        console.log(response.data);

                        console.log('token renew at '+new Date());
                        state.user.token = response.data.newtoken;

                        
                        if(localStorage.userMPDC != undefined){

                            let jsonTok = {
                                token: response.data.newtoken
                            };

                            localStorage.setItem('userMPDC', JSON.stringify(jsonTok));
                            // console.log(JSON.parse(localStorage.pengguna));
                        }


                        resolve(response)
                    })
                    .catch((error) => {
                        // set user on state to null
                        // clear local storage
                        // goto login page
                        router.push('/').catch(()=>{})  //kene ke LOGIN
                        // reject(error)
                    })
                }

            })
        },
    },
    getters: {
        user(state) {
            return state.user
        },
        token(state) {
            return state.user.token
        },
        error(state) {
            return state.error
        },
        loading(state) {
            return state.loading
        },
        userRole(state) {
            return state.user.role
        },

    },
}
