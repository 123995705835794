
//  === Import ===
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import router from "./router/index";
import Vuex from "vuex";
import store from './store/index';
import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";
import exportingInit from "highcharts/modules/exporting";
import appMixins from '@/mixins/globalMixins'
import tileLayer from '@/mixins/map/tileLayer'



// === Deploy/Setup Import ===

// Vuex state
Vue.use(Vuex);


// Highchart
Stock(Highcharts);
exportingInit(Highcharts);
Vue.use(HighchartsVue);


// init Global Mixins
Vue.mixin(appMixins)
Vue.mixin(tileLayer)

// Create event bus
export const bus = new Vue


//  init Axios
Vue.prototype.axios = axios;


// off Notification tip
Vue.config.productionTip = false;


//axios interceptors
let isRefreshing = false


// === Create/Deploy App main system development ===
new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
  created() {
    this.$store.dispatch('checkUserLocalStorage');
    // alert(this.$store.getters.token)

    // renew jst every 10 min
    var renewjwt_interval = null;

    this.$store.dispatch('refreshToken'); 

    renewjwt_interval = setInterval(()=>{ 

        this.$store.dispatch('refreshToken'); 

    }, 10 * 60 * 1000);

},
}).$mount("#app");