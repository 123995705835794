import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#015781',
                secondary: '#30aabc',
                thirdy: '#91e4fb',

            },
            dark: {},
        },
    },
    icons: {
        iconfont: 'mdi',
    },

});
