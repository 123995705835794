var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"background-header",attrs:{"fixed":"","app":"","elevate-on-scroll":"","dark":""}},[_c('v-img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/logo/logo_kementerian.png"),"max-width":"60"}}),_c('v-img',{attrs:{"src":require("@/assets/img/logo/logo_mpdc.png"),"max-width":"100"}}),_c('div',{staticClass:"title-text pl-3",staticStyle:{"line-height":"0.5 !important"}},[_c('v-toolbar-title',{staticClass:"primary--text text-bold"},[_vm._v("MARINE WATER QUALITY MONITORING SYSTEM")]),_c('p',{staticClass:"text-subtitle-1 ma-0 pa-0 black--text"},[_vm._v(" Department of Fisheries Malaysia ")])],1),_c('v-spacer'),_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.$route.name == 'Dashboard'? 'thirdy': 'white',"icon":"","rounded":""},on:{"click":function($event){return _vm.goToPage('/dashboard')}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"role":"img"}},[_vm._v("mdi-view-dashboard-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Dashboard")])])]}}])}),_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.$route.name == 'Report'? 'thirdy': 'white',"icon":"","rounded":""},on:{"click":function($event){return _vm.goToPage('/report')}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"role":"img"}},[_vm._v("mdi-folder-file-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Report")])])]}}])}),_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","icon":"","rounded":""},on:{"click":_vm.logoutSystem}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"role":"img"}},[_vm._v("mdi-logout-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Logout")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }