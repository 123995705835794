<template>
  <v-footer
    id="app-footer"
    padless
    color="primary"
    class="px-0"
    height="150%"
    v-if="$route.meta.showFooter"
  >
    <!-- <v-container> -->

      <v-row>

        <v-col cols="2" class="pb-0 pr-2" align="right" justify="right">
          <v-img
          class="mb-2"
          src="@/assets/img/wallpaper/nre_building.png"
          max-width="130"
          ></v-img>
        </v-col>

        <v-col cols="4" class="white--text pb-0 pl-2" align="left" justify="left">
          <p>
            <span class="thirdy--text text-bold">
              Fisheries Conservation & Protection Division
            </span> <br>

            <small>Department of Fisheries Malaysia</small> <br>

            <small>Wisma Tani, Level 3, Block Tower 4G2, Precinct 4,</small> <br>

            <small>Federal Government Administration Centre, 62628 Putrajaya</small>
          </p>
        </v-col>

        <v-col class="pb-0 pl-15">
          <small
          class="my-1 white--text display-block caption dont-break"
          >
            <a
            class="white--text textDecorationNone linkSelected"
            href="mailto:btlps@dof.gov.my"
            >
              <v-icon small class="white--text mr-2 mb-1">mdi-email</v-icon>
              btlps@dof.gov.my
            </a> 
          </small>
          <small
          class="my-1 white--text display-block caption dont-break"
          >
            <a
            class="white--text textDecorationNone linkSelected"
            href="https://marinepark.dof.gov.my/en"
            target="_blank"
            >
              <v-icon small class="white--text mr-2 mb-1">mdi-web</v-icon>
              https://marinepark.dof.gov.my/en
            </a> 
          </small>
          <small
          class="my-1 white--text display-block caption dont-break"
          >
            <a
            class="white--text textDecorationNone linkSelected"
            href="tel:03 - 8870 2499"
            >
              <v-icon small class="white--text mr-2 mb-1">mdi-phone</v-icon>
              03 - 8870 2499
            </a> 
          </small>
        </v-col>

      </v-row>

    <!-- </v-container> -->
  </v-footer>

</template>

<script>
  export default {
    data: () => ({

    }),
  }
</script>

<style lang="scss" scoped>
@import '~scss/main';

.linkSelected:hover, .linkSelected > a:hover{
  color: #91e4fb !important;
}

</style>